import React from "react"
import Faqs from "../molecules/Faqs"
import SectionTitle from "../atoms/SectionTitle"

const FaqsSection = ({ faqs, searchQuery, componentEC }) => {
  return (
    <div className="cc-section--horizontal-space cc-section--vertical-space">
      <SectionTitle
        title={`Frequently Asked <span>Questions</span>`}
        titleEC={`justify-center`}
        componentEC={`text-center ` + componentEC}
      />
      <div className="mt-12">
        <Faqs faqs={faqs} searchQuery={searchQuery} /> {/* Pass searchQuery to Faqs */}
      </div>
    </div>
  )
}

export default FaqsSection
